import axios from "axios";
export function createHttpClient(baseURL) {
  var client = axios.create({
    baseURL: baseURL
  });
  client.interceptors.request.use(function (config) {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
    config.meta = config.meta || {};
    config.meta.requestStartedAt = new Date().getTime();
    return config;
  });
  client.errorListeners = [];
  client.interceptors.response.use(function (r) {
    var _a;

    var meta = r.config.meta;
    var responseTime = new Date().getTime() - meta.requestStartedAt;
    meta.responseTime = responseTime;
    console.debug("Execution time for: ".concat((_a = r.config.method) === null || _a === void 0 ? void 0 : _a.toUpperCase(), " ").concat(r.config.url, " - ").concat(responseTime, " ms"));
    return r;
  }, function (error) {
    client.errorListeners.forEach(function (l) {
      return l(error);
    });
    return Promise.reject(error);
  });

  client.addErrorListener = function (listener) {
    this.errorListeners.push(listener);
  };

  client.removeErrorListener = function (listener) {
    var i = this.errorListeners.findIndex(function (l) {
      return l === listener;
    });
    this.errorListeners.splice(i, 1);
  };

  client.setApiLocale = function (locale) {
    this.defaults.headers.common['Accept-Language'] = locale.replace(/_/g, '-');
  };

  return client;
}