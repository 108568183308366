import { useEffect, useRef } from "react";

function propsAreSame(a, b) {
  for (var i in a) {
    if (b[i] !== a[i]) {
      return false;
    }
  }

  return true;
}

export default function useEffectOnce(handler, trackingValues) {
  var runRef = useRef(false);
  var trackingRef = useRef(trackingValues);
  useEffect(function () {
    if (!runRef.current || !propsAreSame(trackingRef.current, trackingValues)) {
      runRef.current = true;
      trackingRef.current = trackingValues;
      handler();
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, trackingValues);
}