var MemoryStorage =
/** @class */
function () {
  function MemoryStorage() {
    this.data = {};
  }

  MemoryStorage.prototype.getItem = function (key) {
    var _a;

    return (_a = this.data[key]) !== null && _a !== void 0 ? _a : null;
  };

  MemoryStorage.prototype.removeItem = function (key) {
    delete this.data[key];
  };

  MemoryStorage.prototype.setItem = function (key, value) {
    this.data[key] = value;
  };

  return MemoryStorage;
}();

export default MemoryStorage;