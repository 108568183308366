var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import OAuthClient, { normalizeRedirectUri } from "./OAuthClient";

var KeycloakClient =
/** @class */
function () {
  function KeycloakClient(_a) {
    var realm = _a.realm,
        baseUrl = _a.baseUrl,
        rest = __rest(_a, ["realm", "baseUrl"]);

    this.realm = realm;
    this.baseUrl = baseUrl;
    this.client = new OAuthClient(__assign({
      baseUrl: this.getOpenIdConnectBaseUrl()
    }, rest));
  }

  KeycloakClient.prototype.getRealmUrl = function () {
    return "".concat(this.baseUrl, "/realms/").concat(this.realm);
  };

  KeycloakClient.prototype.getOpenIdConnectBaseUrl = function () {
    return "".concat(this.getRealmUrl(), "/protocol/openid-connect");
  };

  KeycloakClient.prototype.getAccountUrl = function (redirect) {
    if (redirect === void 0) {
      redirect = '/';
    }

    redirect !== null && redirect !== void 0 ? redirect : redirect = document.location.toString();
    var redirectUri = normalizeRedirectUri(redirect);
    return "".concat(this.getRealmUrl(), "/account/?referrer=").concat(this.client.clientId, "&referrer_uri=").concat(encodeURIComponent(redirectUri), "#/personal-info");
  };

  KeycloakClient.prototype.createLogoutUrl = function (_a) {
    var _b = _a.redirectPath,
        redirectPath = _b === void 0 ? '/' : _b;
    var redirectUri = normalizeRedirectUri(redirectPath);
    var queryString = "client_id=".concat(encodeURIComponent(this.client.clientId), "&post_logout_redirect_uri=").concat(encodeURIComponent(redirectUri));
    return "".concat(this.getOpenIdConnectBaseUrl(), "/logout?").concat(queryString);
  };

  KeycloakClient.prototype.logout = function (redirectPath) {
    if (redirectPath === void 0) {
      redirectPath = '/';
    }

    this.client.logout();

    if (false !== redirectPath) {
      document.location.href = this.createLogoutUrl({
        redirectPath: redirectPath
      });
    }
  };

  return KeycloakClient;
}();

export default KeycloakClient;