var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (g && (g = 0, op[0] && (_ = 0)), _) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import axios from "axios";
import CookieStorage from "../storage/cookieStorage";
import jwtDecode from "jwt-decode";
export var loginEventType = 'login';
export var refreshTokenEventType = 'refreshToken';
export var logoutEventType = 'logout';
export var sessionExpiredEventType = 'sessionExpired';

var OAuthClient =
/** @class */
function () {
  function OAuthClient(_a) {
    var clientId = _a.clientId,
        clientSecret = _a.clientSecret,
        baseUrl = _a.baseUrl,
        _b = _a.storage,
        storage = _b === void 0 ? new CookieStorage() : _b,
        tokenStorageKey = _a.tokenStorageKey;
    this.listeners = {};
    this.tokenStorageKey = 'token';
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.baseUrl = baseUrl;

    if (!storage) {
      throw new Error("Unable to store session");
    }

    this.storage = storage;
    this.tokenStorageKey = tokenStorageKey !== null && tokenStorageKey !== void 0 ? tokenStorageKey : 'token';
  }

  OAuthClient.prototype.getAccessToken = function () {
    var _a;

    return (_a = this.fetchTokens()) === null || _a === void 0 ? void 0 : _a.access_token;
  };

  OAuthClient.prototype.getTokenType = function () {
    var _a;

    return (_a = this.fetchTokens()) === null || _a === void 0 ? void 0 : _a.token_type;
  };

  OAuthClient.prototype.getRefreshToken = function () {
    var _a;

    return (_a = this.fetchTokens()) === null || _a === void 0 ? void 0 : _a.refresh_token;
  };

  OAuthClient.prototype.getUsername = function () {
    var _a;

    return (_a = this.getDecodedToken()) === null || _a === void 0 ? void 0 : _a.preferred_username;
  };

  OAuthClient.prototype.isAuthenticated = function () {
    var tokens = this.fetchTokens();

    if (tokens) {
      return tokens.refresh_expires_at > Math.ceil(new Date().getTime() / 1000) + 1;
    }

    return false;
  };

  OAuthClient.prototype.isAccessTokenValid = function () {
    var tokens = this.fetchTokens();

    if (tokens) {
      return tokens.expires_at > Math.ceil(new Date().getTime() / 1000) + 1;
    }

    return false;
  };

  OAuthClient.prototype.getDecodedToken = function () {
    var accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    return jwtDecode(accessToken);
  };

  OAuthClient.prototype.logout = function () {
    this.clearSessionTimeout();
    this.triggerEvent(logoutEventType);
    this.storage.removeItem(this.tokenStorageKey);
    this.tokensCache = undefined;
  };

  OAuthClient.prototype.registerListener = function (event, callback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }

    this.listeners[event].push(callback);
  };

  OAuthClient.prototype.unregisterListener = function (event, callback) {
    if (!this.listeners[event]) {
      return;
    }

    var index = this.listeners[event].findIndex(function (c) {
      return c === callback;
    });

    if (index >= 0) {
      delete this.listeners[event][index];
    }
  };

  OAuthClient.prototype.getTokenFromAuthCode = function (code, redirectUri) {
    return __awaiter(this, void 0, void 0, function () {
      var res;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.getToken({
              code: code,
              grant_type: 'authorization_code',
              redirect_uri: redirectUri
            })];

          case 1:
            res = _a.sent();
            this.persistTokens(res);
            this.handleSessionTimeout(res);
            return [4
            /*yield*/
            , this.triggerEvent(loginEventType, {
              response: res
            })];

          case 2:
            _a.sent();

            return [2
            /*return*/
            , res];
        }
      });
    });
  };

  OAuthClient.prototype.getTokenFromRefreshToken = function () {
    var _a, _b;

    return __awaiter(this, void 0, void 0, function () {
      var res, e_1;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            _c.trys.push([0, 3,, 4]);

            return [4
            /*yield*/
            , this.getToken({
              refresh_token: this.getRefreshToken(),
              grant_type: 'refresh_token'
            })];

          case 1:
            res = _c.sent();
            this.handleSessionTimeout(res);
            return [4
            /*yield*/
            , this.triggerEvent(refreshTokenEventType, {
              response: res
            })];

          case 2:
            _c.sent();

            return [2
            /*return*/
            , res];

          case 3:
            e_1 = _c.sent();
            console.debug('e', e_1);

            if (axios.isAxiosError(e_1)) {
              if (((_b = (_a = e_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === 'invalid_grant') {
                this.sessionExpired();
              }
            }

            throw e_1;

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  OAuthClient.prototype.getTokenFromClientCredentials = function () {
    return __awaiter(this, void 0, void 0, function () {
      var res;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.getToken({
              grant_type: 'client_credentials',
              client_id: this.clientId,
              client_secret: this.clientSecret
            })];

          case 1:
            res = _a.sent();
            return [4
            /*yield*/
            , this.triggerEvent(refreshTokenEventType, {
              response: res
            })];

          case 2:
            _a.sent();

            return [2
            /*return*/
            , res];
        }
      });
    });
  };

  OAuthClient.prototype.wrapPromiseWithValidToken = function (callback) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!!this.isAccessTokenValid()) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , this.getTokenFromRefreshToken()];

          case 1:
            _a.sent();

            _a.label = 2;

          case 2:
            return [4
            /*yield*/
            , callback(this.fetchTokens())];

          case 3:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  OAuthClient.prototype.createAuthorizeUrl = function (_a) {
    var _b = _a.redirectPath,
        redirectPath = _b === void 0 ? '/auth' : _b,
        connectTo = _a.connectTo,
        state = _a.state;
    var redirectUri = normalizeRedirectUri(redirectPath);
    var queryString = "response_type=code&client_id=".concat(encodeURIComponent(this.clientId), "&redirect_uri=").concat(encodeURIComponent(redirectUri)).concat(connectTo ? "&kc_idp_hint=".concat(encodeURIComponent(connectTo)) : '').concat(state ? "&state=".concat(encodeURIComponent(state)) : '');
    return "".concat(this.baseUrl, "/auth?").concat(queryString);
  };

  OAuthClient.prototype.getTokenResponse = function () {
    return this.fetchTokens();
  };

  OAuthClient.prototype.triggerEvent = function (type, event) {
    if (event === void 0) {
      event = {};
    }

    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            event.type = type;

            if (!this.listeners[type]) {
              return [2
              /*return*/
              , Promise.resolve()];
            }

            return [4
            /*yield*/
            , Promise.all(this.listeners[type].map(function (func) {
              return func(event);
            }).filter(function (f) {
              return !!f;
            }))];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  OAuthClient.prototype.handleSessionTimeout = function (res) {
    var _this = this;

    this.clearSessionTimeout();
    this.sessionTimeout = setTimeout(function () {
      _this.sessionExpired();
    }, res.refresh_expires_in * 1000);
  };

  OAuthClient.prototype.sessionExpired = function () {
    this.triggerEvent(sessionExpiredEventType);
    this.logout();
  };

  OAuthClient.prototype.clearSessionTimeout = function () {
    if (this.sessionTimeout) {
      clearTimeout(this.sessionTimeout);
      this.sessionTimeout = undefined;
    }
  };

  OAuthClient.prototype.persistTokens = function (token) {
    var now = Math.ceil(new Date().getTime() / 1000);
    token.expires_at = now + token.expires_in;
    token.refresh_expires_at = now + token.refresh_expires_in;
    this.tokensCache = token;
    this.storage.setItem(this.tokenStorageKey, JSON.stringify(token));
  };

  OAuthClient.prototype.fetchTokens = function () {
    if (this.tokensCache) {
      return this.tokensCache;
    }

    var t = this.storage.getItem(this.tokenStorageKey);

    if (t) {
      return this.tokensCache = JSON.parse(t);
    }
  };

  OAuthClient.prototype.getToken = function (data) {
    return __awaiter(this, void 0, void 0, function () {
      var params, formData, res;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            params = new URLSearchParams();
            formData = __assign(__assign({}, data), {
              client_id: this.clientId,
              client_secret: this.clientSecret
            });
            Object.keys(formData).map(function (k) {
              if (undefined !== formData[k]) {
                params.append(k, formData[k]);
              }
            });
            return [4
            /*yield*/
            , axios.post("".concat(this.baseUrl, "/token"), params)];

          case 1:
            res = _a.sent().data;
            this.persistTokens(res);
            return [2
            /*return*/
            , res];
        }
      });
    });
  };

  return OAuthClient;
}();

export default OAuthClient;
export function configureClientAuthentication(client, oauthClient, onTokenError) {
  client.interceptors.request.use(createAxiosInterceptor(oauthClient, "getTokenFromRefreshToken", onTokenError));
}
export function configureClientCredentialsGrantType(client, oauthClient, onTokenError) {
  client.interceptors.request.use(createAxiosInterceptor(oauthClient, "getTokenFromClientCredentials", onTokenError));
}

function createAxiosInterceptor(oauthClient, method, onTokenError) {
  var _this = this;

  return function (config) {
    return __awaiter(_this, void 0, void 0, function () {
      var p, p_1, e_2, err;

      var _a;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (method === "getTokenFromRefreshToken" && (config.anonymous || !oauthClient.isAuthenticated())) {
              return [2
              /*return*/
              , config];
            }

            if (!!oauthClient.isAccessTokenValid()) return [3
            /*break*/
            , 7];
            p = oauthClient.tokenPromise;
            if (!p) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , p];

          case 1:
            _b.sent();

            return [3
            /*break*/
            , 7];

          case 2:
            p_1 = oauthClient[method]();
            oauthClient.tokenPromise = p_1;
            _b.label = 3;

          case 3:
            _b.trys.push([3, 5, 6, 7]);

            return [4
            /*yield*/
            , p_1];

          case 4:
            _b.sent();

            return [3
            /*break*/
            , 7];

          case 5:
            e_2 = _b.sent();

            if (e_2.isAxiosError) {
              err = e_2;

              if (err.response && [400].includes(err.response.status)) {
                oauthClient.logout();
                onTokenError && onTokenError(err);
                throw e_2;
              }
            }

            return [3
            /*break*/
            , 7];

          case 6:
            oauthClient.tokenPromise = undefined;
            return [7
            /*endfinally*/
            ];

          case 7:
            (_a = config.headers) !== null && _a !== void 0 ? _a : config.headers = {};
            config.headers['Authorization'] = "".concat(oauthClient.getTokenType(), " ").concat(oauthClient.getAccessToken());
            return [2
            /*return*/
            , config];
        }
      });
    });
  };
}

export function normalizeRedirectUri(uri) {
  if (uri.indexOf('/') === 0) {
    var url = [window.location.protocol, '//', window.location.host].join('');
    return "".concat(url).concat(uri);
  }

  return uri;
}